import { Injectable, Injector } from '@angular/core';
import { ActionPerformed, PermissionStatus, PushNotifications, Token } from '@capacitor/push-notifications';
import { Crud } from '@amirsavand/ngx-common';
import { PushDevice } from '@app/shared/interfaces/push-device';
import { GetResult, Preferences } from '@capacitor/preferences';
import { DeviceKind } from '@app/shared/enums/device-kind';
import { Router } from '@angular/router';
import { NativeNotificationData } from '@app/shared/interfaces/native-notification-data';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class NativePushNotificationService {
  /** API Crud for registering devices. */
  private readonly crud = new Crud<PushDevice, void>({
    injector: this.injector,
    name: 'account/profile/push-devices',
  });

  /**
   * Storing FCM Token
   * to check if the current token is different
   * or not. Upon being different, send the
   * server the new token.
   */
  private token: string | null = null;

  /**
   * Native push notifications
   * (FCM - Firebase Cloud Messaging) is enabled
   * if platform is native.
   *
   * @returns whether native push notifications
   * is supported.
   */
  public get isEnabled(): boolean {
    return Capacitor.isNativePlatform();
  }

  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
  ) {}

  /** Listen to registration success. */
  private handleToken(): void {
    PushNotifications.addListener('registration', (token: Token): void => {
      this.registerDevice(token.value);
    });
  }

  /**
   * Register a device.
   * If given token already exists in the
   * preferences, then prevent API call.
   *
   * @param token Device token.
   */
  private registerDevice(token: string): void {
    if (this.token === token) {
      return;
    }
    this.crud
      .create({
        kind: DeviceKind.MOBILE,
        registration_token: token,
      })
      .subscribe({
        next: (): void => {
          console.debug('[NativePushNotificationService] Device Token registered');
          this.token = token;
          Preferences.set({ key: 'fcm-token', value: token });
        },
      });
  }

  /**
   * Handle registration errors, this can happen
   * due to issue on setup.
   */
  private handleError(): void {
    PushNotifications.addListener('registrationError', (error: any): void => {
      console.debug(`[NativePushNotificationService] Registration error - ${error}`);
    });
  }

  /** On notification click. */
  private handleNotificationClick(): void {
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed): void => {
      if (notification.actionId !== 'tap') {
        return;
      }
      console.debug('[NativePushNotificationService] Notification clicked and redirecting');
      const data: NativeNotificationData = notification.notification.data;
      this.router.navigate(['/', data.tenant, data.room], {
        queryParams: {
          message: data.message,
          thread: data.parent || null,
        },
      });
    });
  }

  /** Initiate native push notification. */
  async initiate(): Promise<void> {
    /** Check if native push notifications should initiate. */
    if (!this.isEnabled) {
      return;
    }
    const result: GetResult = await Preferences.get({ key: 'fcm-token' });
    this.token = result.value;
    this.handleToken();
    this.handleError();
    this.handleNotificationClick();
  }

  /**
   * Request permission to use push notifications.
   *
   * Android will just grant without prompting.
   * iOS will prompt user and return if they granted permission or not.
   */
  public requestPermissions(): void {
    if (!this.isEnabled) {
      return;
    }
    PushNotifications.requestPermissions().then((result: PermissionStatus): void => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM.
        PushNotifications.register();
      }
    });
  }
}
