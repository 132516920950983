import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { Observable } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

/**
 * An Angular HTTP interceptor designed to handle
 * HTTP requests specific to tenant-related functionality.
 *
 * It incorporates mechanisms for canceling pending
 * requests and optimizing the management of HTTP
 * observables.
 */
@Injectable()
export class TenantHttpInterceptorService implements HttpInterceptor {
  constructor(private readonly appService: AppService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('/TID/')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      /** Using share to make the observable multicast. */
      share(),
      /** Unsubscribe when destroy emits. */
      takeUntil(this.appService.tenantPendingRequests),
    );
  }
}
