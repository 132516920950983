import { NgxSnackbarComponent } from '@amirsavand/ngx-snackbar';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, NgZone, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { AppService } from '@app/app.service';
import { getVersion } from '@app/shared/functions/get-version';
import { AuthService } from '@app/shared/services/auth.service';
import { CapacitorService } from '@app/shared/services/capacitor.service';
import { CloudMessagingService } from '@app/shared/services/cloud-messaging.service';
import { environment } from '@environments/environment';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AUTH_SERVICE, ConfigsService, ThemeService } from '@SavandBros/savandbros-ngx-common';
import { filter, map } from 'rxjs/operators';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NativePushNotificationService } from '@app/shared/services/native-push-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgxSnackbarComponent, LoadingBarModule, RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(AUTH_SERVICE) private readonly authService: AuthService,
    @Optional() private readonly swUpdate: SwUpdate | null,
    private readonly configsService: ConfigsService,
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appService: AppService,
    private readonly cloudMessagingService: CloudMessagingService,
    private readonly nativePushNotificationService: NativePushNotificationService,
    private readonly themeService: ThemeService,
    private readonly capacitorService: CapacitorService,
  ) {
    this.onInitialized();
  }

  private onInitialized(): void {
    /** On Deeplink URL opened, navigate to route. */
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent): void => {
      this.ngZone.run((): void => {
        const slug: string | undefined = event.url.split('.com').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  ngOnInit(): void {
    /** Print out app version and environment. */
    console.debug(`Savand Bros Chat [${environment.name}] [${getVersion()}]`);
    /** Handle PWA version update. */
    this.swUpdate?.versionUpdates.subscribe({
      next: (data: VersionEvent): void => {
        if (data.type === 'VERSION_READY') {
          this.appService.confirm('Web app update is available.', ['Update', 'Dismiss'], true).subscribe({
            next: (result: boolean): void => {
              if (result) {
                this.document.defaultView?.location.reload();
              }
            },
          });
        }
      },
    });
    /** Initiate AuthService. */
    this.authService.initiate();
    /** Initiate theme. */
    this.themeService.initiate();
    /** Initiate cloud messaging. */
    this.cloudMessagingService.initiate();
    /** Initiate native push notification. */
    this.nativePushNotificationService.initiate();
    /** Watch authentication status and load configs initially. */
    this.authService.isAuth.subscribe({
      next: (isAuth: boolean): void => {
        if (isAuth) {
          /** Request permission when the user is authenticated. */
          this.nativePushNotificationService.requestPermissions();
          this.configsService.load();
          this.authService.profileRetrieve(false).subscribe();
        }
      },
    });
    /** Initiate capacitor service. */
    this.capacitorService.initiate();
    /** Watch navigation and trigger the {@see AppService.onPageChange}. */
    this.router.events
      .pipe(
        filter((event: NavigationEnd | unknown): boolean => event instanceof NavigationEnd),
        map((): ActivatedRoute => this.route),
        map((activatedRoute: ActivatedRoute): ActivatedRoute => {
          while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
          }
          return activatedRoute;
        }),
        filter((activatedRoute: ActivatedRoute): boolean => activatedRoute.outlet === 'primary'),
      )
      .subscribe({
        next: (activatedRoute: ActivatedRoute): void => {
          this.appService.onPageChange(activatedRoute);
        },
      });
  }
}
