import { InlineStorage } from '@amirsavand/ngx-common';
import { NgxSnackbarService } from '@amirsavand/ngx-snackbar';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from '@app/shared/interfaces/profile';
import { ApiService } from '@app/shared/services/api.service';
import { environment } from '@environments/environment';
import { API_SERVICE, AuthService as AuthServiceBase } from '@SavandBros/savandbros-ngx-common';
import { Observable, tap } from 'rxjs';
import { TenantRegister } from '../interfaces/tenant-register';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthServiceBase<Profile> {
  public override readonly version = new InlineStorage<number>('version', 4);

  constructor(
    ngxSnackbarService: NgxSnackbarService,
    httpClient: HttpClient,
    router: Router,
    @Inject(DOCUMENT) document: Document,
    @Inject(API_SERVICE) apiService: ApiService,
  ) {
    super(apiService, environment, router, httpClient, ngxSnackbarService, document);
  }

  /**
   * Register a new tenant.
   *
   * When successful, we need to reload the profile cache.
   */
  public registerTenant(payload: TenantRegister): Observable<TenantRegister> {
    return this.httpClient.post<TenantRegister>(`${this.apiBase}account/register-tenant/`, payload).pipe(
      tap({
        next: (): void => {
          this.profileRetrieve(false).subscribe();
        },
      }),
    );
  }
}
