/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { SENTRY_DSN } from '@app/shared/consts';
import { getVersion } from '@app/shared/functions/get-version';
import { environment } from '@environments/environment';
import type { Event, Exception } from '@sentry/angular-ivy';
import * as Sentry from '@sentry/angular-ivy';

/** Check if not in development environment. */
if (!environment.development) {
  /** Initialise sentry. */
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: environment.sentry,
    environment: environment.name,
    release: getVersion(),
    beforeSend: (event: Event): Event | null => {
      const exception: Exception | undefined = event.exception?.values?.[0];
      const prevent = Boolean(
        exception?.value?.endsWith('401 OK') ||
          exception?.value?.endsWith('401 Unauthorized') ||
          exception?.value?.startsWith('Non-Error exception captured with keys'),
      );
      if (prevent) {
        console.debug(`[SENTRY] Error reporting was prevented: ${exception?.value}`);
        return null;
      }
      return event as Event;
    },
  });
}

/** Boostrap the app. */
bootstrapApplication(AppComponent, appConfig).catch((error: Error): void => console.error(error));
