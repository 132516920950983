import { CRUD_BASE, CRUD_HTTP_CLIENT } from '@amirsavand/ngx-common';
import { NGX_MODAL_DEFAULT_OPTIONS, NgxModalSize } from '@amirsavand/ngx-modal';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from '@app/app.routes';
import { ApiService } from '@app/shared/services/api.service';
import { AuthService } from '@app/shared/services/auth.service';
import { environment } from '@environments/environment';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {
  API_SERVICE,
  AUTH_SERVICE,
  ChunkErrorHandler,
  ConfigLabelPipe,
  EDITOR_CONFIGS,
  EditorConfigs,
  ENVIRONMENT,
  HttpInterceptorService,
  isMobile,
} from '@SavandBros/savandbros-ngx-common';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/angular-ivy';
import { FIREBASE_OPTIONS } from '@app/shared/consts';
import { TenantHttpInterceptorService } from '@app/shared/services/tenant-http-interceptor.service';
import { FIREBASE_OPTIONS as FIREBASE_OPTIONS_TOKEN } from '@angular/fire/compat';

/** todo Conditionally import these modules. */
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    // provideServiceWorker('ngsw-worker.js', { enabled: !environment.development }),
    importProvidersFrom([
      LoadingBarModule,
      LoadingBarHttpClientModule,
      // AngularFireModule.initializeApp(FIREBASE_OPTIONS),
      // AngularFireMessagingModule,
    ]),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: FIREBASE_OPTIONS_TOKEN, useValue: FIREBASE_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: TenantHttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: ErrorHandler, useClass: ChunkErrorHandler },
    { provide: NGX_MODAL_DEFAULT_OPTIONS, useValue: { minWidth: NgxModalSize.MEDIUM, maxWidth: NgxModalSize.MEDIUM } },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: CRUD_BASE, useValue: `${environment.api}v1/` },
    { provide: CRUD_HTTP_CLIENT, useClass: HttpClient },
    { provide: AUTH_SERVICE, useClass: AuthService },
    { provide: API_SERVICE, useClass: ApiService },
    {
      provide: EDITOR_CONFIGS,
      useValue: {
        toolbar: {
          heading: false,
          codeBlock: true,
          anchor: true,
        },
        enterIsSend: true,
        isMobile: Capacitor.isNativePlatform() || isMobile(),
        useNgZone: Capacitor.isNativePlatform(),
      } as EditorConfigs,
    },
    ConfigLabelPipe,
  ],
};
