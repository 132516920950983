import { Crud, InlineStorage, Timer } from '@amirsavand/ngx-common';
import { Inject, Injectable, Injector } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { DeviceKind } from '@app/shared/enums/device-kind';
import { NotificationData } from '@app/shared/interfaces/notification-data';
import { PushDevice } from '@app/shared/interfaces/push-device';
import { AuthService } from '@app/shared/services/auth.service';
import { environment } from '@environments/environment';
import { AUTH_SERVICE } from '@SavandBros/savandbros-ngx-common';
import firebase from 'firebase/compat';
import { Capacitor } from '@capacitor/core';

@Injectable({ providedIn: 'root' })
export class CloudMessagingService {
  /**
   * Service worker `message` event
   * `action` value.
   *
   * @see handleNotificationClick
   */
  private readonly notificationClickActionName = 'NOTIFICATION_CLICK';

  /** Service worker scope name. */
  private readonly serviceWorkerScope = 'firebase-cloud-messaging-push-scope';

  /** Service worker script URL. */
  private readonly serviceWorkerScript = 'firebase-messaging-sw.js';

  /** API Crud for registering devices. */
  private readonly crud = new Crud<PushDevice, void>({
    injector: this.injector,
    name: 'account/profile/push-devices',
  });

  /**
   * InlineStorage instance. Storing FCM Token
   * to check if the current token is different
   * or not. Upon being different, send the
   * server the new token.
   */
  private readonly sent = new InlineStorage<string | null>('fcm-token', null);

  /**
   * Service worker registration to check for its
   * state for being activated.
   */
  private registration?: ServiceWorkerRegistration;

  /**
   * Timer being used to check if
   * {@link registration} has `activated` state.
   * Upon being activated calls
   * {@link handleToken}.
   *
   * Firebase doesn't wait for service worker to
   * be activated, thus raising error.
   */
  private readonly timer = new Timer({
    rate: 500,
    autoStart: false,
    repeat: true,
    onInvoke: (): void => {
      if (!this.authService.isAuth.value) {
        return;
      }
      if (this.registration?.active?.state === 'activated') {
        console.debug('[CloudMessagingService] Service worker is set up');
        this.timer.stop();
        this.handleToken();
      }
    },
  });

  /**
   * Cloud messaging (firebase) is enabled if:
   * - Service worker is supported
   * - Enabled in this environment
   *
   * @returns whether cloud messaging is supported.
   */
  public get isEnabled(): boolean {
    return !Capacitor.isNativePlatform() && 'serviceWorker' in navigator && environment.firebase;
  }

  constructor(
    @Inject(AUTH_SERVICE) private readonly authService: AuthService,
    private readonly router: Router,
    private readonly injector: Injector,
    private readonly messaging: AngularFireMessaging,
  ) {}

  /** Register Firebase service worker and start timer. */
  private registerServiceWorker(): void {
    navigator.serviceWorker
      .register(this.serviceWorkerScript, {
        scope: this.serviceWorkerScope,
      })
      .then((registration: ServiceWorkerRegistration): void => {
        this.registration = registration;
        this.timer.start();
      });
  }

  /**
   * On foreground messages, meaning when the
   * client window is focused.
   */
  private handleMessage(): void {
    this.messaging.messages.subscribe({
      next: (data: firebase.messaging.MessagePayload): void => {
        console.debug(`[CloudMessagingService] FCM foreground message received. ${data.data}`);
      },
    });
  }

  /**
   * Handle token changes and try registering
   * the device.
   */
  private handleToken(): void {
    this.messaging.tokenChanges.subscribe({
      next: (token: string | null): void => {
        this.registerDevice(token);
      },
    });
  }

  /**
   * Handle system notification click.
   *
   * This method listens to service worker's
   * `message` event, which gets posted by
   * `firebase-messaging-sw.js` file,
   * and redirects the user based on the data
   * it carries.
   *
   * The event gets triggered when:
   * - The client window exists (tab not closed).
   * - The client window is not focused.
   */
  private handleNotificationClick(): void {
    navigator.serviceWorker.addEventListener('message', (event: MessageEvent<NotificationData>): void => {
      if (!('action' in event.data)) {
        return;
      }
      /** Prevent if action is not {@link notificationClickActionName}. */
      if (event.data.action !== this.notificationClickActionName) {
        return;
      }
      console.debug('[CloudMessagingService] Notification clicked and redirecting');
      this.router.navigate(['/', event.data.data.tenant, event.data.data.room], {
        queryParams: {
          message: event.data.data.message,
          thread: event.data.data.parent || null,
        },
      });
    });
  }

  /**
   * Register a device
   *
   * @param token Device token.
   */
  public registerDevice(token: string | null): void {
    if (!token) {
      this.sent.clear();
      return;
    }
    if (this.sent.value === token) {
      return;
    }
    this.crud
      .create({
        kind: DeviceKind.WEB,
        registration_token: token,
      })
      .subscribe({
        next: (): void => {
          console.debug('[CloudMessagingService] Device Token registered');
          this.sent.value = token;
        },
      });
  }

  /** Initiate Firebase Cloud Messaging. */
  public initiate(): void {
    /** Check if service worker should initiate. */
    if (!this.isEnabled) {
      return;
    }
    console.debug('[CloudMessagingService] Initiating FCM');
    /** Register firebase service worker. */
    this.registerServiceWorker();
    /** Handle foreground messages. */
    this.handleMessage();
    /** Handle notification click. */
    this.handleNotificationClick();
  }
}
