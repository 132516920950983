import { FirebaseOptions } from '@firebase/app';

export const FIREBASE_OPTIONS: FirebaseOptions = {
  projectId: 'savand-bros-chat',
  appId: '1:740265510576:web:815def190f6d5c88189d75',
  storageBucket: 'savand-bros-chat.appspot.com',
  apiKey: 'AIzaSyAjd0N9ggNuolJ-vqFLP-aS_cZKuElXb-I',
  authDomain: 'savand-bros-chat.firebaseapp.com',
  messagingSenderId: '740265510576',
};
