import { Routes } from '@angular/router';
import { isAuthenticatedGuard } from '@SavandBros/savandbros-ngx-common';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./index/index.component').then(m => m.IndexComponent),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.routes').then(m => m.routes),
  },
  {
    path: ':pk',
    loadChildren: () => import('./tenant/tenant.routes').then(m => m.routes),
    canActivate: [isAuthenticatedGuard],
  },
];
